



















































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

@Component
export default class QuickStart extends Vue {
  public company = '';
  public email = '';
  public title = '';
  public condition = false;

  public get year() {
    return new Date().getFullYear();
  }

  created() {
    const { query } = this.$route;
    this.company = typeof query.company === 'string' ? query.company : '';
    this.email = typeof query.email === 'string' ? query.email : '';
    this.title = typeof query.title === 'string' ? query.title : '';
  }

  mounted() {
    const ref = this.$refs['condition'] as HTMLBceSelectElement;

    // Doesn't work with a v-model because Vue turns the input object into a
    // string.
    ref.value = this.condition ? ['true'] : [];
    ref.addEventListener('input', (e: any) => {
      this.condition = !!e.target.value.length;
    });
  }

  @Watch('company')
  @Watch('email')
  @Watch('title')
  public watch() {
    const { query } = this.$route;
    const { company, email, title } = this;

    if (
      query.company !== company ||
      query.email !== email ||
      query.title !== title
    ) {
      this.$router.replace({ query: { company, email, title } });
    }
  }

  public async start() {
    if (!this.condition) return;

    this.$app.loader(this.$t('loader-message-2'));
    const stopLoader = () => this.$app.loader();

    try {
      const { company, email, title } = this;
      await this.$user.quickStart({ company, email, research: title });
      stopLoader();
      window.location.href = this.$auth.createURL('/');
    } catch (e) {
      const message =
        e.message === 'ACCOUNT_EXISTS'
          ? 'Dit e-mailadres is al in gebruik.'
          : e.message;

      this.$bce.message(message, 10, 'error');
      stopLoader();
    }
  }
}
